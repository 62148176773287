import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../lib/util';
import Button from '../../atoms/Button';
import { Spinner } from '../../atoms/Spinner';
import Text from '../../atoms/Text';
import { MapWrapper } from '../../blocks/MapWrapper';
import { RewardsSwitcher } from '../../molecules/RewardsSwitcher';
import { SignInDialog } from '../../molecules/SignInDialog';
import { EventInfoHeader } from '../../organisms/EventInfoHeader';
import { ListingsFilters } from '../../organisms/ListingsFilters';
import { ListingsFiltersModal } from '../../organisms/ListingsFiltersModal';
import { NonGuestListListings } from '../../organisms/NonGuestListListings';
import { NonGuestListPreCheckoutModal } from '../../organisms/NonGuestListPreCheckoutModal';
import styles from './NonGuestListEventPage.module.scss';
import { usePresenter } from './NonGuestListEventPage.presenter';
import type { NonGuestListEventPagePresenterProps, NonGuestListEventPageProps } from './NonGuestListEventPage.types';

export const NonGuestListEventPage: React.FC<NonGuestListEventPageProps> = (props) => {
  const presenterProps: NonGuestListEventPagePresenterProps = usePresenter(props);

  const {
    isSignedOut,
    eventMetadata,
    listingsMetadata,
    areListingsLoading,
    quantity,
    priceRange,
    priceMinMaxRange,
    onListingsFiltersChanged,
    isListingsFiltersModalOpen,
    openListingsFiltersModal,
    closeListingsFiltersModal,
    leftSubContentRef,
    isSvgMapPanZoomEnabled,
    shouldOpenPreCheckout,
    isListingDetailLoading,
    listingDetailQueryParams,
    listingDetail,
    className,
  } = presenterProps;

  const { t } = useTranslation();

  const { isMobile } = useWindowSize();

  const eventInfoHeader: ReactNode = (
    <EventInfoHeader
      eventMetaData={eventMetadata}
      shouldRenderBackButton={isMobile}
      theme={isMobile ? 'dark' : 'light'}
    />
  );

  const map: ReactNode = (
    <div className={styles.mapContainer}>
      <MapWrapper
        isExclusiveEvent={eventMetadata.isExclusiveEvent}
        isSvgMapPanZoomEnabled={isSvgMapPanZoomEnabled}
      />
    </div>
  );

  /**
   * Note: Listing header is displayed only if there is at least one listing card rendered.
   * This is handled by switching between 'display: none;' and 'display: flex;' in CSS.
   */
  const listingsHeader: ReactNode = (
    <div className={styles.listingsHeader}>
      <Text
        type='Subheading'
        size='ExtraSmall'
        style='Regular'
        colour='SubduedDark'
        value={t('nonGuestListEventPage.chooseYourSeats')}
      />
      {isMobile && (
        <Button
          type='Icon'
          style='Text'
          size='Medium'
          icon={{
            asset: 'Settings',
            style: 'SubduedDark',
          }}
          ariaLabel={t('listingsFilters.openListingsFiltersModal')}
          onClick={openListingsFiltersModal}
          className={styles.listingsFiltersButton}
        />
      )}
    </div>
  );

  const rewardsSwitcher: ReactNode = isSignedOut && (
    <RewardsSwitcher
      eventMetadata={eventMetadata}
      theme='light'
      className={styles.rewardsSwitcher}
    />
  );

  const listings: ReactNode = (
    <NonGuestListListings eventMetadata={eventMetadata} />
  );

  const leftContent: ReactNode = (
    <div className={styles.leftContent}>
      {eventInfoHeader}
      <div ref={leftSubContentRef} className={styles.leftSubContent}>
        <div className={styles.leftTopSubContent}>
          {isMobile && map}
          <SignInDialog className={styles.signInDialog} />
          {listingsHeader}
          {rewardsSwitcher}
        </div>
        <div className={styles.leftCenterSubContent}>
          {listings}
        </div>
        {!isMobile && !!listingsMetadata && (
          <div className={styles.leftBottomSubContent}>
            <ListingsFilters
              quantity={quantity}
              priceRange={priceRange}
              priceMinMaxRange={priceMinMaxRange}
              onListingsFiltersChanged={onListingsFiltersChanged}
            />
          </div>
        )}
      </div>
    </div>
  );

  const rightContent: ReactNode = (
    <div className={styles.rightContent}>
      {!isMobile && map}
    </div>
  );

  return (<>
    {(areListingsLoading || isListingDetailLoading) && <Spinner isOverlay={true} />}
    <div className={cx(styles.nonGuestListEventPage, className)}>
      {leftContent}
      {rightContent}
    </div>
    {shouldOpenPreCheckout && !!listingDetailQueryParams && !!listingDetail && (
      <NonGuestListPreCheckoutModal
        {...presenterProps}
        listingDetailQueryParams={listingDetailQueryParams}
        listingDetail={listingDetail}
      />
    )}
    {isListingsFiltersModalOpen && isMobile && (
      <ListingsFiltersModal
        quantity={quantity}
        priceRange={priceRange}
        priceMinMaxRange={priceMinMaxRange}
        onListingsFiltersChanged={onListingsFiltersChanged}
        closeListingsFiltersModal={closeListingsFiltersModal}
      />
    )}
  </>);
};
