import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../atoms/Icon';
import Image from '../../atoms/Image';
import Text from '../../atoms/Text';
import styles from './ExclusiveBadge.module.scss';
import { usePresenter } from './ExclusiveBadge.presenter';
import type { ExclusiveBadgeProps } from './ExclusiveBadge.types';
import { ExclusiveBadgePropsByStyle } from './ExclusiveBadge.constant';

export const ExclusiveBadge: React.FC<ExclusiveBadgeProps> = (props) => {
  const {
    style,
    theme,
    className,
  } = usePresenter(props);

  const { t } = useTranslation();

  const exclusiveBadgeIcon: ReactNode = style !== 'savorExclusive'
    ? 
      <Icon 
        asset={ExclusiveBadgePropsByStyle[style].icon.iconAsset}
        style={ExclusiveBadgePropsByStyle[style].icon.iconStyle}
      />
    :
      <Image 
        imageSrc={ExclusiveBadgePropsByStyle[style].image.imageSrc} 
        className={styles.image}
      />;

  return (
    <div className={cx(styles.exclusiveBadge, styles[`${theme}ExclusiveBadge`], className)}>
      {exclusiveBadgeIcon}
      <Text
        size='ExtraSmall'
        style='Regular'
        colour='InteractionBlue70'
        value={t(ExclusiveBadgePropsByStyle[style].textKey)}
      />
    </div>
  );
};
