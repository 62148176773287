import React, { useContext, useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '.';
import { URLs } from '../../lib/constants';
import { checkIsProtectedRoute, displayMaintenanceWindow } from '../../lib/util';
import { useAnalyticsManager } from '../analytics/useAnalyticsManager';
import ErrorHandler from '../error';
import { TopNavProvider } from '../topNav';
import { UnauthorizedRedirect } from './AuthContext';

const CheckUserAccess: React.FC = () => {
  const { isAccountLoading, account, error, verifyAccessToken } = useContext(AuthContext);

  const { search, pathname } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const assertion = query.get('assertion');
  const code = query.get('code');
  const lastFour = query.get('lastFour');
  const stateParam = query.get('state');
  const redirectUrl = stateParam ? decodeURIComponent(stateParam).replace(window.location.origin, '') : undefined;

  const navigate = useNavigate();

  const { trackEvent } = useAnalyticsManager();
  const utmSource = query.get('utm_source');
  const utmTerm = query.get('utm_term');
  const utmMedium = query.get('utm_medium');
  const utmCampaign = query.get('utm_campaign');
  const utmTarget = query.get('utm_target');
  const utmAdgroupId = query.get('utm_adgroup_id');
  const utmKeywordId = query.get('utm_keyword_id');
  const utmContent = query.get('utm_content');

  // Tracking marketing visit
  useEffect(() => {
    if (utmSource) {
      trackEvent('marketing_visit', {
        utm: {
          medium: utmMedium,
          source: utmSource,
          campaign: utmCampaign,
          term: utmTerm,
          adgroupId: utmAdgroupId,
          target: utmTarget,
          keywordId: utmKeywordId,
          content: utmContent,
        },
      });
    }
  }, [trackEvent, utmSource, utmMedium, utmCampaign, utmTerm, utmAdgroupId, utmTarget, utmKeywordId, utmContent]);

  useEffect(() => {
    if (!isAccountLoading) {
      if (code) {
        verifyAccessToken({ access_token: code, token_type: 'code' });
        trackEvent('login');
        navigate(redirectUrl || { search: '' }, { replace: true });
      }

      if (assertion) {
        verifyAccessToken({ access_token: assertion, last_four: lastFour });
        trackEvent('login');
        query.delete('assertion');
        navigate({ search: query.toString() }, { replace: true });
      }
    }
  }, [isAccountLoading, code, redirectUrl, assertion, lastFour, query, verifyAccessToken, trackEvent, navigate]);

  const isMaintenance: boolean = displayMaintenanceWindow();

  if (isAccountLoading || code || assertion) {
    return <Outlet />;
  }

  if (pathname === URLs.HelpPage) {
    return <Outlet />;
  }

  if (error) {
    return <ErrorHandler isAuthError={true} error={error} />;
  }

  if (!account && checkIsProtectedRoute(pathname) && !isMaintenance) {
    return <UnauthorizedRedirect />;
  }

  return <Outlet />;
};

const ProtectedRoute: React.FC = () => {
  return (
    <TopNavProvider>
      <CheckUserAccess />
    </TopNavProvider>
  );
};

export default ProtectedRoute;
