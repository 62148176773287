import { ExclusiveBadgeProps, ExclusiveBadgeStyle } from '.';
import CreditCard from '../../../resources/icons/CreditCard.svg';

export const ExclusiveBadgePropsByStyle = {
  cardholderExclusive: {
    icon: {
      iconAsset: 'Sparkles',
      iconStyle: 'Yellow',
    },
    textKey: 'exclusiveBadge.cardholderExclusive',
  },
  exclusiveAccess: {
    icon: {
      iconAsset: 'Lock',
      iconStyle: 'CoreBlue50',
    },
    textKey: 'exclusiveBadge.exclusiveAccess',
  },
  savorExclusive: {
    image: {
      imageSrc: CreditCard,
    },
    textKey: 'exclusiveBadge.exclusiveAccess',
  },
} as const satisfies Record<ExclusiveBadgeStyle, Pick<ExclusiveBadgeProps, 'textKey' & ('icon' | 'image')>>;