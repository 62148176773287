import { useContext } from 'react';
import { getEventMetadata, getEventStatus, getGetTicketsLabelKey } from '../../../lib/eventUtils';
import type { EventMetadata, EventStatus } from '../../../lib/types';
import { removeHtmlTags } from '../../../lib/util';
import { trackCardClickEvent, useAnalyticsManager } from '../../../modules/analytics';
import { AuthContext } from '../../../modules/auth';
import GenericEventImageAsset from '../../../resources/images/GenericEventImage.jpg';
import type { EventCardPresenterProps, EventCardProps } from './EventCard.types';

export const usePresenter = (props: EventCardProps): EventCardPresenterProps => {
  const {
    event,
    shouldShowImage = true,
    shouldShowDescription = false,
    pageName,
    sectionTitleKey,
    sectionIndex,
    eventCardIndex,
  } = props;

  const { trackEvent } = useAnalyticsManager();

  const { isSignedOut } = useContext(AuthContext);

  // Important: This component is memoized so there is no need to use useMemo here in the presenter.

  const eventMetadata: EventMetadata = getEventMetadata({ event });
  const eventImageUrl: string | undefined = shouldShowImage ? (eventMetadata.eventModalMobileImageUrl || GenericEventImageAsset) : undefined;
  const eventDescription: string | undefined = shouldShowDescription ? removeHtmlTags(eventMetadata.eventDescription) : undefined;
  const eventStatus: EventStatus | undefined = getEventStatus({ event, shouldCheckForExclusiveEvents: true });
  const getTicketsLabelKey: string = getGetTicketsLabelKey({ isEventSoldOut: eventStatus?.status === 'soldOut' });

  const onEventCardClick = () => trackCardClickEvent({
    trackEvent,
    pageName,
    sectionTitleKey,
    sectionIndex,
    cardIndex: eventCardIndex,
    cardName: event.name,
  });

  return {
    ...props,
    isSignedOut,
    eventMetadata,
    eventImageUrl,
    eventDescription,
    eventStatus,
    getTicketsLabelKey,
    onEventCardClick,
  };
};
