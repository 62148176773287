import cx from 'classnames';
import React, { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import { ExclusiveBadge } from '../../molecules/ExclusiveBadge';
import { EventInfoModal } from '../EventInfoModal';
import { BackButtonIconStylesByTheme, CardholderExclusiveBadgeThemesByTheme, InfoButtonAssetsByTheme, InfoButtonStylesByTheme, PrimaryColoursByTheme, SecondaryColoursByTheme } from './EventInfoHeader.constants';
import styles from './EventInfoHeader.module.scss';
import { usePresenter } from './EventInfoHeader.presenter';
import type { EventInfoHeaderProps } from './EventInfoHeader.types';

export const EventInfoHeader: React.FC<EventInfoHeaderProps> = (props) => {
  const {
    isSignedOut,
    eventMetaData,
    isInfoModalShown,
    onInfoButtonClick,
    closeInfoModal,
    shouldRenderBackButton = false,
    onBackButtonClick,
    theme,
  } = usePresenter(props);

  const {
    eventName,
    eventDescription,
    eventDateTimeAndVenueDetails,
    isExclusiveEvent,
  } = eventMetaData;

  const { t } = useTranslation();

  const backButton: ReactNode = shouldRenderBackButton && (
    <Button
      type='Icon'
      style='Text'
      size='Medium'
      icon={{
        asset: 'ArrowLeft',
        style: BackButtonIconStylesByTheme[theme],
      }}
      ariaLabel={t('common.goBack')}
      onClick={onBackButtonClick}
      className={styles.iconButton}
    />
  );

  const cardholderExclusiveBadge: ReactNode = isExclusiveEvent && (
    <ExclusiveBadge
      style={isSignedOut ? 'exclusiveAccess' : 'cardholderExclusive'}
      theme={CardholderExclusiveBadgeThemesByTheme[theme]}
    />
  );

  const eventNameView: ReactNode = (
    <Text
      type='Decoration'
      size='Medium'
      style='SemiBold'
      colour={PrimaryColoursByTheme[theme]}
      value={eventName}
      className={styles.truncatable}
    />
  );

  const infoButton: ReactNode = !!eventDescription && (
    <Button
      type='Icon'
      style='Text'
      size='Medium'
      icon={{
        asset: InfoButtonAssetsByTheme[theme],
        style: InfoButtonStylesByTheme[theme],
      }}
      ariaLabel={t('event.openEventInfoModal')}
      onClick={onInfoButtonClick}
      className={styles.iconButton}
    />
  );

  const eventDateTimeAndVenue: ReactNode = (
    <Text
      size='Medium'
      style='Regular'
      colour={SecondaryColoursByTheme[theme]}
      value={t('event.shortWeekDayShortDateTimeVenueName', eventDateTimeAndVenueDetails)}
      className={cx(styles.eventDateTimeAndVenue, styles.truncatable)}
    />
  );

  return (<>
    <div className={cx(styles.eventInfoHeader, styles[`${theme}EventInfoHeader`])}>
      {backButton}
      <div className={styles.content}>
        {cardholderExclusiveBadge}
        <div className={styles.eventInfoRow}>
          {eventNameView}
          {infoButton}
        </div>
        {eventDateTimeAndVenue}
      </div>
    </div>
    {isInfoModalShown && (
      <EventInfoModal
        eventMetaData={eventMetaData}
        closeInfoModal={closeInfoModal}
      />
    )}
  </>);
};
