import type { Account, AccountCardDetail, AssociatedAccount } from '../modules/auth/types';
import type { LoyaltyCurrencies, LoyaltyCurrency } from '../modules/partnership';
import CreditCard from '../resources/icons/CreditCard.svg';
import { IsIntegerRewardUnit } from './constants';
import { convertStringToSnakeCase, formatNumberToLocaleString, parseNumber } from './util';

export const getAccountCardDetails = (params: {
  account: Account | undefined;
  loyaltyCurrencies: LoyaltyCurrencies | undefined;
}): AccountCardDetail[] => {
  const { account, loyaltyCurrencies } = params;

  if (!account?.associatedAccounts.length) {
    return [];
  }

  // TODO: AK: Loop through all associated accounts for multi-card experience
  return [account.associatedAccounts[0]].map((associatedAccount: AssociatedAccount) => {
    const {
      loyalty_program: {
        /** Image URL of the account card */
        program_logo_url: accountCardImageUrl,
        /** Last 4 digits of the account card number as string, e.g. '1234' */
        last_four: accountCardLastFourDigits = '',
        /** Number of available reward units, e.g. 123456 */
        number_of_units: numberOfUnits,
        /** Number of dollars that is equivalent to 1 reward unit, e.g. 0.01 */
        redeem_per_dollar: redeemPerRewardUnit, // Note: API property name 'redeem_per_dollar' is misleading, it should be called 'redeem_per_unit'.
        /** Loyalty unit display name from API, e.g. 'miles', 'rewards cash', 'points' */
        unit_display_name: unitDisplayName = '',
        /** Loyalty unit name from API, e.g. 'MILES', 'REWARDS CASH', 'POINTS'. To be used to call API to create order. */
        loyalty_unit_name: loyaltyUnitName = '',
      },
    } = associatedAccount;

    /** Lower cased reward unit name from API, e.g. 'miles', 'rewards cash', 'points', etc. */
    const apiRewardUnitName: string = (unitDisplayName || loyaltyUnitName).toLowerCase();

    /** True only for miles and points units */
    const isIntegerRewardUnit: boolean = IsIntegerRewardUnit[apiRewardUnitName] ?? false;

    /** Validated total number of available reward units, e.g. 123456. 0 if the value is not a number. */
    const rewardUnitsTotal: number = parseNumber(numberOfUnits, { minValue: 0, allowDecimals: !isIntegerRewardUnit }) ?? 0;

    const loyaltyCurrency: LoyaltyCurrency | undefined = apiRewardUnitName && loyaltyCurrencies ? loyaltyCurrencies[convertStringToSnakeCase(apiRewardUnitName)] : undefined;

    /** Validated number of reward units that is equivalent to $1, e.g. 100. 1 if the value is not a number. */
    const rewardUnitsPerDollar: number = parseNumber(1 / (loyaltyCurrency?.rate ?? redeemPerRewardUnit), { minValue: 0, allowDecimals: true }) ?? 1;

    /** Lower cased reward unit name, e.g. 'miles', 'rewards cash', 'points', etc. */
    const rewardUnitName: string = loyaltyCurrency?.display_name || apiRewardUnitName;

    /** Formatted number of available reward units, e.g. '123,456 miles', '123,456 points', '$123,456' */
    const formattedRewardUnitsTotal: string = rewardUnitName
      ? formatNumberToLocaleString({ num: rewardUnitsTotal, unitName: rewardUnitName, shouldIncludeUnitName: true })
      : '';

    return {
      accountCardImageUrl: accountCardImageUrl || CreditCard,
      accountCardLastFourDigits,
      rewardUnitsTotal,
      rewardUnitsPerDollar,
      rewardUnitName,
      loyaltyCurrency,
      isIntegerRewardUnit,
      formattedRewardUnitsTotal,
    };
  });
};
