import { useCallback, useContext, useEffect, useMemo, useState, type ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { checkCheckoutEligibility } from '../../../lib/checkoutUtils';
import { DELIVERY_ID_PARAM, EVENT_ID_PARAM, EXCLUSIVE_LISTINGS_PARAM, OrderErrorProps, QUANTITY_PARAM, STEP_PARAM, URLs } from '../../../lib/constants';
import { getListingDetailMetadata, getTotalPricesForQuantity, processDeliveryOption } from '../../../lib/listingUtils';
import type { ListingDetailMetadata, TotalPrices } from '../../../lib/types';
import { addQueryParam, getTicketListingTranslationKey, useNumericQueryParamFromUrl } from '../../../lib/util';
import { trackSelectContentEvent, useAnalyticsManager } from '../../../modules/analytics';
import { AuthContext } from '../../../modules/auth';
import { LoyaltyCurrenciesContext } from '../../../modules/loyaltyCurrencies';
import { useAppendQueryParamsToUrl } from '../../../modules/navigation/Navigation.hooks';
import { buildGoBackHistoryState, redirectToSignInURL } from '../../../modules/navigation/Navigation.utils';
import type { TicketAlertModalProps } from '../../organisms/TicketAlertModal';
import type { TextDropdownOption } from '../TextDropdown';
import type { PreCheckoutDetailsPresenterProps, PreCheckoutDetailsProps, TicketsByLogo } from './PreCheckoutDetails.types';
import { getQuantityDropdownOptions, getServiceFeeText, getTicketsByLogo } from './PreCheckoutDetails.utils';

export const usePresenter = (props: PreCheckoutDetailsProps): PreCheckoutDetailsPresenterProps => {
  const { listingDetailQueryParams, listingDetail, shouldShowAipOverride } = props;

  const {
    isSignedOut,
    account,
    setAccountExternally,
    selectedAccountCardIndex,
    selectedAccountCardDetail,
    selectAccountCardByIndex,
  } = useContext(AuthContext);

  const { selectedLoyaltyCurrency } = useContext(LoyaltyCurrenciesContext);

  const { trackEvent } = useAnalyticsManager();

  useEffect(() => {
    trackEvent('select_ticket', {
      ecommerce: {
        content_type: 'select_ticket',
        items: listingDetail,
      },
    });
  }, [listingDetail, trackEvent]);

  const { t } = useTranslation();

  const navigate = useNavigate();

  /** Detailed information about event listing detail */
  const listingDetailMetadata: ListingDetailMetadata = useMemo(
    () => getListingDetailMetadata({
      listingDetail,
      shouldShowAipOverride,
      loyaltyCurrency: selectedAccountCardDetail ? selectedAccountCardDetail.loyaltyCurrency : selectedLoyaltyCurrency,
    }),
    [listingDetail, selectedAccountCardDetail, selectedLoyaltyCurrency, shouldShowAipOverride]);

  const { eventMetadata, listingMetadata } = listingDetailMetadata;
  const { isPayWithRewardsOnly, eventMaxPurchaseLimit } = eventMetadata;
  const { shouldShowAip, cashPrice, loyaltyPrice, loyaltyUnitName, quantities, hasCp1BrokerId } = listingMetadata;

  /** Logo to display in 'tickets by' section (capitalOne or vividSeats) */
  const ticketsByLogo: TicketsByLogo = useMemo(() => getTicketsByLogo({ hasCp1BrokerId }), [hasCp1BrokerId]);

  /** Translation key for ticket notes */
  const ticketNotesKey: string = useMemo(
    () => getTicketListingTranslationKey(listingDetail, 'ticket_details'),
    [listingDetail],
  );

  /** Array of quantity dropdown options sorted by quantity values in ascending order */
  const quantityDropdownOptions: TextDropdownOption[] = useMemo(
    () => getQuantityDropdownOptions({ quantities }),
    [quantities],
  );

  /** Validated quantity. Undefined if quantity is invalid, e.g. when it is non-numeric or it is less than 1. */
  const quantity: number | undefined = useNumericQueryParamFromUrl(QUANTITY_PARAM, { minValue: 1 });

  const { appendQueryParamsToUrl } = useAppendQueryParamsToUrl();

  /** Callback function to handle changes in quantity selection */
  const onQuantityChanged = useCallback((event: ChangeEvent<{ value: string; }>) => {
    trackSelectContentEvent(
      trackEvent,
      'Production',
      'Quantity Filter',
      t('preCheckoutDetails.quantityDropdownLabel'),
    );

    appendQueryParamsToUrl({ [QUANTITY_PARAM]: event.target.value });
  }, [t, trackEvent, appendQueryParamsToUrl]);

  const {
    /** Formatted total cash price (with cents) for selected quantity in Quantity dropdown, e.g. $123,456.00 */
    formattedTotalCashPrice,
    /** Formatted total loyalty price for selected quantity in Quantity dropdown, e.g. 123,456 (for miles or points), $123,456 (for cash rewards) */
    formattedTotalLoyaltyPrice,
  }: TotalPrices = useMemo(
    () => getTotalPricesForQuantity({ cashPrice, loyaltyPrice, loyaltyUnitName, quantity: quantity || 0 }),
    [cashPrice, loyaltyPrice, loyaltyUnitName, quantity],
  );

  /**
   * Displayed text for listing service fees:
   * - For non-AIP:
   *   - Undefined for exclusive events
   *   - 'Plus fees' for non-exclusive events
   * - For AIP:
   *   - 'Includes fees' if event venue is in California or Maryland
   *   - 'Includes $X.XX in fees' if event is in other locations
   */
  const serviceFeeText: string | undefined = useMemo(
    () => getServiceFeeText({ shouldShowAip, listingDetailMetadata, quantity: quantity || 0 }),
    [shouldShowAip, listingDetailMetadata, quantity],
  );

  const [ticketAlertModalProps, setTicketAlertModalProps] = useState<TicketAlertModalProps | undefined>(undefined);

  const insufficientRewardUnitsModalProps: TicketAlertModalProps = useMemo(() => ({
    title: t('errors.insufficientRewardUnits.title'),
    message: t('errors.insufficientRewardUnits.message'),
    primaryButtonLabel: t('errors.insufficientRewardUnits.button'),
    onPrimaryButtonClick: () => setTicketAlertModalProps(undefined),
    onCloseButtonClick: () => setTicketAlertModalProps(undefined),
  }), [t]);

  const purchaseLimitErrorModalProps: TicketAlertModalProps = useMemo(() => ({
    title: t('errors.purchaseLimitError.title'),
    message: t('errors.purchaseLimitError.message', { eventMaxPurchaseLimit, count: eventMaxPurchaseLimit }),
    primaryButtonLabel: t('errors.purchaseLimitError.button'),
    onPrimaryButtonClick: () => setTicketAlertModalProps(undefined),
    onCloseButtonClick: () => setTicketAlertModalProps(undefined),
  }), [t, eventMaxPurchaseLimit]);

  /** True while the checkout is being validated */
  const [isValidatingCheckout, setIsValidatingCheckout] = useState<boolean>(false);

  /** Function to initiate the checkout process */
  const handleCheckout = useCallback(
    async () => {
      try {
        // Redirect to sign-in page if user is not signed in
        if (!account) {
          redirectToSignInURL();
          return;
        }

        if (!isValidatingCheckout && quantity) {
          setIsValidatingCheckout(true);

          trackSelectContentEvent(
            trackEvent,
            'Production',
            'Precheckout',
            t('preCheckoutDetails.checkout'),
          );

          const {
            /** Re-fetched user account */
            refetchedAccount,
            /** Error state. True if any of the API calls has failed. */
            isCheckoutEligibilityApiError,
            /**
             * Indicates whether user does not have enough reward units to pay for the tickets.
             * If user applied reward units then True if user no longer has those reward units available.
             * In addition, if the event only allows payment with rewards then True if user does not have enough reward units to pay for the tickets.
             */
            areInsufficientRewardUnits,
            /**
             * Indicates whether the user would cross the limit of the maximum tickets per account per event should they proceed with the purchase.
             * E.g. if the limit is 4 and user has previously purchased 2 tickets for the same event, then:
             * - If they try to buy 1 or 2 more tickets for the same event then the flag will be false (2 + 1 <= 4, 2 + 2 <= 4).
             * - If they try to buy 3 or more tickets for the same event then the flag will be true (2 + 3 > 4).
             */
            isPurchaseLimitCrossed,
          } = await checkCheckoutEligibility({
            listingDetail,
            listingDetailQueryParams,
            selectedQuantity: quantity,
            appliedRewardUnits: 0,
            isPayWithRewardsOnly,
            eventMaxPurchaseLimit,
            setAccountExternally,
            selectedAccountCardIndex,
            selectAccountCardByIndex,
            trackEvent,
          });

          if (!refetchedAccount || isCheckoutEligibilityApiError) {
            navigate(URLs.ErrorPage, { state: OrderErrorProps });
            setIsValidatingCheckout(false);
            return;
          }

          if (areInsufficientRewardUnits) {
            setTicketAlertModalProps(insufficientRewardUnitsModalProps);
            setIsValidatingCheckout(false);
            return;
          } else if (isPurchaseLimitCrossed) {
            setTicketAlertModalProps(purchaseLimitErrorModalProps);
            setIsValidatingCheckout(false);
            return;
          }

          setTicketAlertModalProps(undefined);

          const newQueryString: string = addQueryParam('', {
            [EVENT_ID_PARAM]: listingDetail.event.id.toString(),
            [QUANTITY_PARAM]: quantity.toString(),
            [STEP_PARAM]: '1',
            [EXCLUSIVE_LISTINGS_PARAM]: hasCp1BrokerId.toString(),
            [DELIVERY_ID_PARAM]: processDeliveryOption(listingDetail.pricing.delivery.id || 0).toString(),
          });

          navigate(
            {
              pathname: `/checkout/${listingDetail.listing.id}`,
              search: newQueryString,
            },
            { state: buildGoBackHistoryState() },
          );

          setIsValidatingCheckout(false);
        }
      } catch {
        navigate(URLs.ErrorPage, { state: OrderErrorProps });
        setIsValidatingCheckout(false);
      }
    },
    [
      t,
      account,
      isValidatingCheckout,
      listingDetail,
      listingDetailQueryParams,
      quantity,
      isPayWithRewardsOnly,
      eventMaxPurchaseLimit,
      setAccountExternally,
      selectedAccountCardIndex,
      selectAccountCardByIndex,
      trackEvent,
      insufficientRewardUnitsModalProps,
      purchaseLimitErrorModalProps,
      hasCp1BrokerId,
      navigate,
    ],
  );

  return {
    ...props,
    isSignedOut,
    listingDetailMetadata,
    ticketsByLogo,
    ticketNotesKey,
    quantityDropdownOptions,
    selectedQuantity: quantity?.toString() || '',
    onQuantityChanged,
    formattedTotalCashPrice,
    formattedTotalLoyaltyPrice,
    loyaltyUnitName,
    serviceFeeText,
    handleCheckout,
    isValidatingCheckout,
    ticketAlertModalProps,
  };
};
