import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { EVENT_TAGS } from '../../../lib/constants';
import { checkArrayMinMaxLength } from '../../../lib/objectUtils';
import { useWindowSize } from '../../../lib/util';
import { useAnalyticsManager } from '../../../modules/analytics/useAnalyticsManager';
import { trackPageViewEvent, trackSelectContentEvent } from '../../../modules/analytics/util';
import { AuthContext } from '../../../modules/auth';
import type { Event, EventsQueryParams } from '../../../modules/partnership';
import { getEvents } from '../../../modules/partnership/api';
import { RegionContext } from '../../../modules/region';
import { TopNavContext } from '../../../modules/topNav';
import { EventCard, type EventCardProps } from '../../molecules/EventCard';
import { EventOrMlbCard, type EventOrMlbCardProps } from '../../molecules/EventOrMlbCard';
import type { CarouselSectionProps } from '../../organisms/CarouselSection';
import { convertDateFilterStateToDateRange, useDateFilterStateFromUrl, type DateFilterState, type DateRange } from '../../organisms/DateFilter';
import { checkIsCurrentRegionSelected, useRegionFilterStateFromUrl, type RegionFilterState } from '../../organisms/RegionFilter';
import { ExclusivesTabCategoryFilterOptionsMap } from '../AllEventsPage';
import { DEFAULT_PAGE_SIZE, MIN_SPOTLIGHT_EVENTS_TO_SHOW, SPOTLIGHT_EVENTS_PAGE_SIZE, TRACKING_PAGE_NAME } from './ExclusiveEventsPage.constants';
import type { ExclusiveEventsPagePresenterProps, ExclusiveEventsPageProps } from './ExclusiveEventsPage.types';
import { buildEventsQueryParams, getAllEventsLinkHref, getCarouselSectionProps, getEventCardProps, getSportsEventCardProps, getSpotlightEventCardProps, scrollToAnchor } from './ExclusiveEventsPage.utils';

export const usePresenter = (props: ExclusiveEventsPageProps): ExclusiveEventsPagePresenterProps => {
  const { isAccountLoading, account } = useContext(AuthContext);

  const { topFixedHeight } = useContext(TopNavContext);

  const { hash } = useLocation();

  const filtersContainerRef = useRef<HTMLDivElement>(null);

  // Support hash links, e.g. #sports, #music, etc. to navigate to specific sections on the page
  useEffect(() => {
    if (hash) {
      // Postpone the execution to allow the app to initialise
      setTimeout(() => scrollToAnchor({ hash, topFixedHeight, filtersContainer: filtersContainerRef.current }), 100);
    }
  }, [hash, topFixedHeight]);

  const { t } = useTranslation();

  const { trackEvent, onError } = useAnalyticsManager();

  const { isMobile } = useWindowSize();

  const {
    areRegionsLoading,
    allRegions: regions,
    allRegionsMap: regionsMap,
    currentRegion,
  } = useContext(RegionContext);

  /** True while account and regions are loading */
  const isRequiredDataLoading: boolean = useMemo(() => isAccountLoading || areRegionsLoading, [isAccountLoading, areRegionsLoading]);

  // Track analytics for page visits
  useEffect(() => {
    trackPageViewEvent(trackEvent, TRACKING_PAGE_NAME.forPageVisits);

    trackEvent(TRACKING_PAGE_NAME.forViewItemList);

    trackEvent('view_item_list', {
      ecommerce: {
        item_list_name: TRACKING_PAGE_NAME.forViewItemList,
      },
    });
  }, [trackEvent]);

  /** Region filter state that is extracted from query parameters in the current URL */
  const regionFilterState: RegionFilterState | undefined = useRegionFilterStateFromUrl({ regionsMap });

  const regionId = useMemo<number | undefined>(() => {
    return !areRegionsLoading
      ? (checkIsCurrentRegionSelected(regionFilterState) ? currentRegion : regionFilterState)?.id
      : undefined;
  }, [areRegionsLoading, regionFilterState, currentRegion]);

  /** Date filter state that is extracted from query parameters in the current URL */
  const dateFilterState: DateFilterState | undefined = useDateFilterStateFromUrl();
  const dateRange: Record<keyof DateRange, string> = useMemo(() => convertDateFilterStateToDateRange(dateFilterState), [dateFilterState]);

  // Spotlight Events

  const spotlightEventsQueryParams: EventsQueryParams | undefined = useMemo(
    () => buildEventsQueryParams({
      isRequiredDataLoading,
      account,
      category: EVENT_TAGS.PromotedProduction,
      additionalQueryParams: { per_page: SPOTLIGHT_EVENTS_PAGE_SIZE },
    }),
    [isRequiredDataLoading, account],
  );

  const {
    data: spotlightEventsResponse,
    isLoading: areSpotlightEventsLoading,
    error: spotlightEventsError,
  } = useQuery(
    ['getSpotlightEvents', ...Object.values(spotlightEventsQueryParams ?? {})],
    () => getEvents(spotlightEventsQueryParams),
    { enabled: !!spotlightEventsQueryParams, onError },
  );

  /**
   * If there are less than 2 spotlight events then return an empty array to hide Spotlight Events carousel section.
   * If there are more than 4 spotlight events then return the first 4 spotlight events.
   */
  const spotlightEvents: Event[] = useMemo(
    () => checkArrayMinMaxLength({
      array: spotlightEventsResponse?.items,
      minLength: MIN_SPOTLIGHT_EVENTS_TO_SHOW,
    }),
    [spotlightEventsResponse?.items],
  );

  const spotlightEventsCarouselSectionProps: CarouselSectionProps<EventCardProps> = useMemo(
    () => getCarouselSectionProps({
      linkHref: undefined,
      isLoading: !spotlightEventsQueryParams || areSpotlightEventsLoading,
      isError: !!spotlightEventsError,
      component: EventCard,
      componentProps: getSpotlightEventCardProps({ spotlightEvents }),
      carouselSectionPropsKey: 'spotlight',
    }),
    [spotlightEventsQueryParams, areSpotlightEventsLoading, spotlightEventsError, spotlightEvents],
  );

  // Sports Events

  const sportsEventsQueryParams: EventsQueryParams | undefined = useMemo(
    () => buildEventsQueryParams({
      isRequiredDataLoading,
      account,
      category: EVENT_TAGS.Sports,
      regionId,
      dateRange,
      // Reduce page size by 1 because Sports carousel section always displays MLB card as the first carousel item
      additionalQueryParams: { per_page: DEFAULT_PAGE_SIZE - 1 },
    }),
    [isRequiredDataLoading, account, regionId, dateRange],
  );

  const {
    data: sportsEventsResponse,
    isLoading: areSportsEventsLoading,
  } = useQuery(
    ['getSportsEvents', ...Object.values(sportsEventsQueryParams ?? {})],
    () => getEvents(sportsEventsQueryParams),
    { enabled: !!sportsEventsQueryParams, onError },
  );

  /** Link to All Events page (Exclusives tab - Sports category) */
  const sportsEventsLinkHref: string | undefined = useMemo(
    () => getAllEventsLinkHref({
      itemsTotal: sportsEventsResponse?.total,
      // For Sports events we need at least 1 event on mobiles and at least 8 events on desktops
      minItems: isMobile ? 0 : DEFAULT_PAGE_SIZE - 1,
      categoryFilterState: ExclusivesTabCategoryFilterOptionsMap.sports,
      regionFilterState,
      dateFilterState,
    }),
    [isMobile, sportsEventsResponse?.total, regionFilterState, dateFilterState],
  );

  const sportsEventsCarouselSectionProps: CarouselSectionProps<EventOrMlbCardProps> = useMemo(
    () => getCarouselSectionProps({
      linkHref: sportsEventsLinkHref,
      isLoading: !sportsEventsQueryParams || areSportsEventsLoading,
      // For Sports events we ignore API errors because Sports carousel section always displays at least MLB card
      isError: false,
      component: EventOrMlbCard,
      // getSportsEventCardProps function always injects MLB card props before sports event card props
      componentProps: getSportsEventCardProps({ sportsEvents: sportsEventsResponse?.items }),
      carouselSectionPropsKey: 'sports',
    }),
    [sportsEventsLinkHref, sportsEventsQueryParams, areSportsEventsLoading, sportsEventsResponse?.items],
  );

  // Music Events

  const musicEventsQueryParams: EventsQueryParams | undefined = useMemo(
    () => buildEventsQueryParams({
      isRequiredDataLoading,
      account,
      category: EVENT_TAGS.Music,
      regionId,
      dateRange,
    }),
    [isRequiredDataLoading, account, regionId, dateRange],
  );

  const {
    data: musicEventsResponse,
    isLoading: areMusicEventsLoading,
    error: musicEventsError,
  } = useQuery(
    ['getMusicEvents', ...Object.values(musicEventsQueryParams ?? {})],
    () => getEvents(musicEventsQueryParams),
    { enabled: !!musicEventsQueryParams, onError },
  );

  /** Link to All Events page (Exclusives tab - Music category) */
  const musicEventsLinkHref: string | undefined = useMemo(
    () => getAllEventsLinkHref({
      itemsTotal: musicEventsResponse?.total,
      // For Music events we need at least 1 event on mobiles and at least 8 events on desktops
      minItems: isMobile ? 0 : DEFAULT_PAGE_SIZE,
      categoryFilterState: ExclusivesTabCategoryFilterOptionsMap.music,
      regionFilterState,
      dateFilterState,
    }),
    [isMobile, musicEventsResponse?.total, regionFilterState, dateFilterState],
  );

  const musicEventsCarouselSectionProps: CarouselSectionProps<EventCardProps> = useMemo(
    () => getCarouselSectionProps({
      linkHref: musicEventsLinkHref,
      isLoading: !musicEventsQueryParams || areMusicEventsLoading,
      isError: !!musicEventsError,
      component: EventCard,
      componentProps: getEventCardProps({ events: musicEventsResponse?.items, carouselSectionPropsKey: 'music' }),
      carouselSectionPropsKey: 'music',
    }),
    [musicEventsLinkHref, musicEventsQueryParams, areMusicEventsLoading, musicEventsError, musicEventsResponse?.items],
  );

  // Dining Events

  const diningEventsQueryParams: EventsQueryParams | undefined = useMemo(
    () => buildEventsQueryParams({
      isRequiredDataLoading,
      account,
      category: EVENT_TAGS.Dining,
      regionId,
      dateRange,
    }),
    [isRequiredDataLoading, account, regionId, dateRange],
  );

  const {
    data: diningEventsResponse,
    isLoading: areDiningEventsLoading,
    error: diningEventsError,
  } = useQuery(
    ['getDiningEvents', ...Object.values(diningEventsQueryParams ?? {})],
    () => getEvents(diningEventsQueryParams),
    { enabled: !!diningEventsQueryParams, onError },
  );

  /** Link to All Events page (Exclusives tab - Dining category) */
  const diningEventsLinkHref: string | undefined = useMemo(
    () => getAllEventsLinkHref({
      itemsTotal: diningEventsResponse?.total,
      // For Dining events we need at least 1 event on mobiles and at least 8 events on desktops
      minItems: isMobile ? 0 : DEFAULT_PAGE_SIZE,
      categoryFilterState: ExclusivesTabCategoryFilterOptionsMap.dining,
      regionFilterState,
      dateFilterState,
    }),
    [isMobile, diningEventsResponse?.total, regionFilterState, dateFilterState],
  );

  const diningEventsCarouselSectionProps: CarouselSectionProps<EventCardProps> = useMemo(
    () => getCarouselSectionProps({
      linkHref: diningEventsLinkHref,
      isLoading: !diningEventsQueryParams || areDiningEventsLoading,
      isError: !!diningEventsError,
      component: EventCard,
      componentProps: getEventCardProps({ events: diningEventsResponse?.items, carouselSectionPropsKey: 'dining' }),
      carouselSectionPropsKey: 'dining',
    }),
    [diningEventsLinkHref, diningEventsQueryParams, areDiningEventsLoading, diningEventsError, diningEventsResponse?.items],
  );

  // Getaways Events

  const getawaysEventsQueryParams: EventsQueryParams | undefined = useMemo(
    () => buildEventsQueryParams({
      isRequiredDataLoading,
      account,
      category: EVENT_TAGS.Travel,
      regionId,
      dateRange,
    }),
    [isRequiredDataLoading, account, regionId, dateRange],
  );

  const {
    data: getawaysEventsResponse,
    isLoading: areGetawaysEventsLoading,
    error: getawaysEventsError,
  } = useQuery(
    ['getGetawaysEvents', ...Object.values(getawaysEventsQueryParams ?? {})],
    () => getEvents(getawaysEventsQueryParams),
    { enabled: !!getawaysEventsQueryParams, onError },
  );

  /** Link to All Events page (Exclusives tab - Getaways category) */
  const getawaysEventsLinkHref: string | undefined = useMemo(
    () => getAllEventsLinkHref({
      itemsTotal: getawaysEventsResponse?.total,
      // For Getaways events we need at least 1 event on mobiles and at least 8 events on desktops
      minItems: isMobile ? 0 : DEFAULT_PAGE_SIZE,
      categoryFilterState: ExclusivesTabCategoryFilterOptionsMap.getaways,
      regionFilterState,
      dateFilterState,
    }),
    [isMobile, getawaysEventsResponse?.total, regionFilterState, dateFilterState],
  );

  const getawaysEventsCarouselSectionProps: CarouselSectionProps<EventCardProps> = useMemo(
    () => getCarouselSectionProps({
      linkHref: getawaysEventsLinkHref,
      isLoading: !getawaysEventsQueryParams || areGetawaysEventsLoading,
      isError: !!getawaysEventsError,
      component: EventCard,
      componentProps: getEventCardProps({ events: getawaysEventsResponse?.items, carouselSectionPropsKey: 'getaways' }),
      carouselSectionPropsKey: 'getaways',
    }),
    [getawaysEventsLinkHref, getawaysEventsQueryParams, areGetawaysEventsLoading, getawaysEventsError, getawaysEventsResponse?.items],
  );

  // Art + Theater Events

  const artTheaterEventsQueryParams: EventsQueryParams | undefined = useMemo(
    () => buildEventsQueryParams({
      isRequiredDataLoading,
      account,
      category: EVENT_TAGS.CultureArts,
      regionId,
      dateRange,
    }),
    [isRequiredDataLoading, account, regionId, dateRange],
  );

  const {
    data: artTheaterEventsResponse,
    isLoading: areArtTheaterEventsLoading,
    error: artTheaterEventsError,
  } = useQuery(
    ['getArtTheaterEvents', ...Object.values(artTheaterEventsQueryParams ?? {})],
    () => getEvents(artTheaterEventsQueryParams),
    { enabled: !!artTheaterEventsQueryParams, onError },
  );

  /** Link to All Events page (Exclusives tab - Art + Theater category) */
  const artTheaterEventsLinkHref: string | undefined = useMemo(
    () => getAllEventsLinkHref({
      itemsTotal: artTheaterEventsResponse?.total,
      // For ArtTheater events we need at least 1 event on mobiles and at least 8 events on desktops
      minItems: isMobile ? 0 : DEFAULT_PAGE_SIZE,
      categoryFilterState: ExclusivesTabCategoryFilterOptionsMap.artTheater,
      regionFilterState,
      dateFilterState,
    }),
    [isMobile, artTheaterEventsResponse?.total, regionFilterState, dateFilterState],
  );

  const artTheaterEventsCarouselSectionProps: CarouselSectionProps<EventCardProps> = useMemo(
    () => getCarouselSectionProps({
      linkHref: artTheaterEventsLinkHref,
      isLoading: !artTheaterEventsQueryParams || areArtTheaterEventsLoading,
      isError: !!artTheaterEventsError,
      component: EventCard,
      componentProps: getEventCardProps({ events: artTheaterEventsResponse?.items, carouselSectionPropsKey: 'artTheater' }),
      carouselSectionPropsKey: 'artTheater',
    }),
    [artTheaterEventsLinkHref, artTheaterEventsQueryParams, areArtTheaterEventsLoading, artTheaterEventsError, artTheaterEventsResponse?.items],
  );

  const onDiningBannerButtonClick = useCallback(() => {
    trackSelectContentEvent(
      trackEvent,
      TRACKING_PAGE_NAME.forBannerClicks,
      'Button',
      t('exclusiveEventsPage.diningBanner.buttonLabel'),
    );
  }, [t, trackEvent]);

  return {
    ...props,
    spotlightEventsCarouselSectionProps,
    sportsEventsCarouselSectionProps,
    musicEventsCarouselSectionProps,
    diningEventsCarouselSectionProps,
    getawaysEventsCarouselSectionProps,
    artTheaterEventsCarouselSectionProps,
    filtersContainerRef,
    regions,
    regionFilterState,
    dateFilterState,
    onDiningBannerButtonClick,
  };
};
