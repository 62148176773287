import type { PresetFilterOption } from '../../organisms/PresetFilter';
import { REWARD_TAGS_BY_PRIORITY, REWARD_TAGS_ID_MAP, REWARD_TAGS_MAP } from './RewardsSwitcher.constants';
import type { RewardTag } from './RewardsSwitcher.types';

// TODO: AK: Add unit tests
/**
 * Gets loyalty unit tags for an account's loyalty program, e.g., C1_MILES, C1_CASH_REWARDS, C1_POINTS
 *
 * This function takes a list of reward tags and filters them by a predefined order of priority
 * from `REWARD_TAGS_BY_PRIORITY`. It ensures that only the valid tags, in the order of importance,
 * are returned based on the available tags in the input.
 *
 * @param {string[] | undefined} tags - A list of reward tags from the account's loyalty program.
 * @returns {RewardTag[] | undefined} - An ordered array of reward tags based on priority, or undefined if no valid tags are found.
 */
export const getRewardTags = (tags: string[] | undefined): RewardTag[] | undefined => {
  // Return undefined if no tags are provided or if the tags array is empty
  if (!tags?.length) {
    return undefined;
  }

  // Filter the available tags using the REWARD_TAGS_BY_PRIORITY array,
  // ensuring only valid reward tags are returned in the defined order of priority
  return REWARD_TAGS_BY_PRIORITY.filter((tag: RewardTag) => tags.includes(tag));
};

// TODO: AK: Add unit tests
/**
 * Constructs the category filter options for the given active tab key.
 *
 * This function converts the reward tags from the event into a list of filter options that can be used
 * in the UI. Each filter option will have a unique id and a name. The id is sourced from `REWARD_TAGS_ID_MAP`
 * (which maps reward tags to their ids), and the name is sourced from `REWARD_TAGS_MAP` (which maps reward tags
 * to their display names). If no id is found for a reward tag, the index is used as the fallback id.
 *
 * @returns {PresetFilterOption[]} - An array of filter options for the UI, each with an id and display name.
 */
export const getRewardTagsFilterOptions = (params: {
  /** RewardTags list from event */
  rewardTags: RewardTag[] | undefined;
}): PresetFilterOption[] => {
  const { rewardTags } = params;

  // Return an empty array if no reward tags are available
  if (!rewardTags?.length) {
    return [];
  }

  // Map each reward tag to a filter option object with id and name
  // If the reward tag is not mapped in REWARD_TAGS_ID_MAP, use the array index as a fallback id
  return rewardTags.map((tag, index) => ({
    id: REWARD_TAGS_ID_MAP[tag] || index,  // Use the mapped id or index as a fallback
    name: REWARD_TAGS_MAP[tag] || '',      // Use the mapped name or an empty string as fallback
  }));
};
