import type { EventMetadata } from '../../../lib/types';
import { handleTagFilterForListings } from '../../../lib/util';
import type { Account } from '../../../modules/auth/types';
import type { Listing, ListingDetailsQueryParams, ListingsQueryParams } from '../../../modules/partnership';
import type { ListingCardProps } from '../../molecules/ListingCard';

export const getListingsQueryParams = (params: {
  account: Account | undefined;
  eventMetadata: EventMetadata | undefined;
  quantityFilterParam: number | undefined;
  minPriceFilterParam: number | undefined;
  maxPriceFilterParam: number | undefined;
}): ListingsQueryParams | undefined => {
  const {
    account,
    eventMetadata,
    quantityFilterParam,
    minPriceFilterParam,
    maxPriceFilterParam,
  } = params;

  if (!eventMetadata) {
    return undefined;
  }

  // Check that if one price param is provided then the other one must be provided as well
  if ((minPriceFilterParam !== undefined && maxPriceFilterParam === undefined) || (minPriceFilterParam === undefined && maxPriceFilterParam !== undefined)) {
    return undefined;
  }

  const listingsQueryParams: ListingsQueryParams = {
    event_id: eventMetadata.id.toString(),
    ...(quantityFilterParam !== undefined ? { quantity: quantityFilterParam } : {}),
    ...(minPriceFilterParam !== undefined && maxPriceFilterParam !== undefined ? { min_price: minPriceFilterParam, max_price: maxPriceFilterParam } : {}),
  };

  return eventMetadata.isExclusiveEvent
    ? handleTagFilterForListings(listingsQueryParams, account)
    : listingsQueryParams;
};

export const getListingDetailQueryParams = (params: {
  eventMetadata: EventMetadata | undefined;
  listingId: string | undefined;
  quantity: number | undefined;
  exclusiveListings: 'true' | 'false' | undefined;
}): ListingDetailsQueryParams | undefined => {
  const {
    eventMetadata,
    listingId,
    quantity,
    exclusiveListings,
  } = params;

  if (!eventMetadata || !listingId || !quantity || !exclusiveListings) {
    return undefined;
  }

  return {
    quantity,
    exclusive_listings: exclusiveListings === 'true',
  };
};

export const getListingCardProps = (params: {
  listings: readonly Listing[] | undefined;
  /** Indicates if the event only allows payment with rewards */
  isPayWithRewardsOnly: boolean;
  /** Flag to enforce AIP pricing */
  shouldShowAipOverride: boolean;
  /**
   * Function that does the following:
   * - Updates hovered section HTML Id
   * - Updates tooltip data with min price and position
   *
   * params.element should be section HTML Id, e.g. id-123
   */
  setHoveredSection?: (params: { element: string; } | undefined) => void;
  /**
   * Function that does the following:
   * - Updates selected section HTML Id
   *
   * params.element should be section HTML Id, e.g. id-123
   *
   * params.selectionMode should be 'without-listing-filtering-by-section' (Listings will not be filtered by selected section. Used for hovering over listing cards).
   *
   * If 'reset-temporary-selection' value is provided then selected section will be reset only if it was selected by hovering over a listing card.
  */
  setSelectedSection?: (params: { element: string; selectionMode: 'without-listing-filtering-by-section'; } | 'reset-temporary-selection') => void;
  /** Optional class name for CSS customisation */
  className?: string;
}): ListingCardProps[] => {
  const {
    listings,
    isPayWithRewardsOnly,
    shouldShowAipOverride,
    setHoveredSection,
    setSelectedSection,
    className,
  } = params;

  return listings?.map((listing: Listing) => ({
    key: listing.id,
    listing,
    isPayWithRewardsOnly,
    shouldShowAipOverride,
    setHoveredSection,
    setSelectedSection,
    className,
  })) ?? [];
};
