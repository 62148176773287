import type { TextColourEnum } from '../../atoms/Text';
import type { RewardsSwitcherTheme, RewardTag } from './RewardsSwitcher.types';

export const RewardsSwitcherTextColorsByTheme = {
  light: 'SubduedDark',
  dark: 'BaseLight',
} as const satisfies Record<RewardsSwitcherTheme, TextColourEnum>;

/** Reward tags sorted by priority for display */
export const REWARD_TAGS_BY_PRIORITY: RewardTag[] = ['C1_MILES', 'C1_CASH_REWARDS', 'C1_POINTS'];

/** Mapping of RewardTag to human-readable strings */
export const REWARD_TAGS_MAP: Record<RewardTag, string> = {
  C1_MILES: 'Miles',
  C1_CASH_REWARDS: 'Rewards cash',
  C1_POINTS: 'Points',
};

/** Mapping of RewardTag to reward unit IDs used in query parameters */
export const REWARD_TAGS_ID_MAP: Record<RewardTag, string> = {
  C1_MILES: 'miles',
  C1_CASH_REWARDS: 'rewards_cash',
  C1_POINTS: 'points',
};

/** Query parameter key for the selected reward unit */
export const CURRENT_REWARDS_PARAM: string = 'currentRewardsUnit';
