import i18n from '../modules/locale/i18n';
import type { Listing, ListingDetails, ListingsResponse, LoyaltyCurrency } from '../modules/partnership';
import { C1_BROKER_ID, EXCLUSIVE_LISTINGS_PARAM, LISTING_ID_PARAM, QUANTITY_PARAM } from './constants';
import { getEventMetadata } from './eventUtils';
import * as listingUtils from './listingUtils';
import type { EventMetadata, ListingDetailMetadata, ListingMetadata, ListingMinPricesBySection, ListingsMetadata, TotalPrices } from './types';
import { addQueryParam, checkAreNumbersInSequence, formatNumberToLocaleString, getTicketListingTranslationKey, isNumber, toHtmlId } from './util';

export const calculateListingPrices = (params: {
  /** Listing object */
  listing: Listing | undefined;
  /** Flag to enforce AIP pricing */
  shouldShowAipOverride: boolean;
  /** Loyalty currency */
  loyaltyCurrency: LoyaltyCurrency | undefined;
}) => {
  const { listing, shouldShowAipOverride, loyaltyCurrency } = params;

  if (!listing) {
    return {};
  }

  /** Non-AIP cash price per unit as number, e.g. 123456 */
  const nonAipCashPrice: number = listing.price_per;

  /** AIP cash price per unit as number, e.g. 123456 */
  const aipCashPrice: number | undefined = listing.all_in_price ?? undefined;

  /** Non-AIP loyalty price per unit as number, e.g. 123456 */
  const nonAipLoyaltyPrice: number | undefined = loyaltyCurrency ? listing.loyalty_prices[loyaltyCurrency.id] : undefined;

  /** AIP loyalty price per unit as number, e.g. 123456 */
  const aipLoyaltyPrice: number | undefined = loyaltyCurrency ? listing.all_in_loyalty_prices?.[loyaltyCurrency.id] : undefined;

  let shouldShowAip: boolean = shouldShowAipOverride || !!listing.show_all_in_price;

  // Reset shouldShowAip flag if AIP cash price and AIP loyalty price are both undefined
  if (shouldShowAip && !isNumber(aipCashPrice) && !isNumber(aipLoyaltyPrice)) {
    shouldShowAip = false;
  }

  return {
    shouldShowAip,
    nonAipCashPrice,
    aipCashPrice,
    cashPrice: shouldShowAip ? aipCashPrice : nonAipCashPrice,
    nonAipLoyaltyPrice,
    aipLoyaltyPrice,
    loyaltyPrice: shouldShowAip ? aipLoyaltyPrice : nonAipLoyaltyPrice,
  };
};

export const calculateListingDetailsPrices = (params: {
  /** Listing details object */
  listingDetails: ListingDetails | undefined;
  /** Flag to enforce AIP pricing */
  shouldShowAipOverride: boolean;
  /** Loyalty currency */
  loyaltyCurrency: LoyaltyCurrency | undefined;
}) => {
  const { listingDetails, shouldShowAipOverride, loyaltyCurrency } = params;

  const shouldShowAip: boolean = shouldShowAipOverride || !!listingDetails?.preselect_delivery_method;

  return listingUtils.calculateListingPrices({ listing: listingDetails?.listing, shouldShowAipOverride: shouldShowAip, loyaltyCurrency });
};

export const getListingsMetadata = (params: {
  listingsResponse: ListingsResponse;
}): ListingsMetadata => {
  const { listingsResponse } = params;

  const {
    /** Map CDN URL, e.g. https://d3pz2kc9e7xd94.cloudfront.net */
    map_cdn_url: mapCdnUrl,
    /** SVG map JSON file name, e.g. fc95dc65-8d37-4976-bf37-02a9e0f515a8.json */
    json_file_name: svgMapJsonFileName,
    /** Static image map URL, e.g. https://d2o50i5c2dr30a.cloudfront.net/44871641-2b79-41dc-8fce-370d4bbeaeb7.jpg */
    static_map_url: staticImageMapUrl,
  } = listingsResponse.meta.legacy_map_info;

  return {
    ...listingsResponse,
    svgMapJsonUrl: svgMapJsonFileName ? `${mapCdnUrl}/${svgMapJsonFileName}` : undefined,
    staticImageMapUrl,
    minPrice: listingsResponse.meta.min_price,
    maxPrice: listingsResponse.meta.max_price,
  };
};

export const getListingSectionHtmlId = (listing: Listing): string => toHtmlId(listing.section);

export const getSeats = (params: {
  listingDetail: ListingDetails | null | undefined;
}): string | undefined => {
  const { listingDetail } = params;

  // if listing detail object does not contain seat info then return undefined
  if (!listingDetail?.pricing.seats?.length) return undefined;

  // Extract the seats from listing detail
  const { seats } = listingDetail.pricing;

  if (seats.length === 1) {
    // If there is only one seat, return it. This covers 'TBD' case.
    return seats[0];
  }

  // Separate seats into positive integer seat numbers
  const numericSeats: number[] = seats.filter(seat => {
    const seatNumber = Number(seat);
    return Number.isInteger(seatNumber) && seatNumber > 0;
  }).map(Number);

  // If there are any non-numeric seats, return the seats as a comma-separated string
  if (seats.length !== numericSeats.length) {
    return seats.join(', ');
  }

  // Sort numeric seats in ascending order
  numericSeats.sort((a, b) => a - b);

  // Check if the sorted numeric seats form a continuous sequence
  const areNumbersInSequence: boolean = checkAreNumbersInSequence(numericSeats);

  // If the seat numbers are continuous, return the range as "first-last"
  // Otherwise, return the seats as a comma-separated string
  return areNumbersInSequence
    ? `${numericSeats[0]}-${numericSeats[numericSeats.length - 1]}`
    : seats.join(', ');
};

export const getListingMetadata = (params: {
  /** Listing object */
  listing: Listing;
  /** Flag to enforce AIP pricing */
  shouldShowAipOverride: boolean;
  /** Loyalty currency */
  loyaltyCurrency: LoyaltyCurrency | undefined;
}): ListingMetadata => {
  const { listing, shouldShowAipOverride, loyaltyCurrency } = params;

  const {
    verbose_section_name: verboseSectionName,
    section,
    row,
    notes: sellerNotes,
    quantity: ticketsTotal,
    quantities: ticketQuantities,
    broker_id: brokerId,
  } = listing;

  const {
    shouldShowAip = false,
    nonAipCashPrice,
    aipCashPrice,
    cashPrice,
    nonAipLoyaltyPrice,
    aipLoyaltyPrice,
    loyaltyPrice,
  } = listingUtils.calculateListingPrices({ listing, shouldShowAipOverride, loyaltyCurrency });

  /** Formatted cash price per unit that is dependent on shouldShowAip flag, e.g. $123,456 */
  const formattedCashPrice: string | undefined = isNumber(cashPrice)
    ? formatNumberToLocaleString({ num: cashPrice, unitName: 'dollars', shouldIncludeUnitName: false })
    : undefined;

  /** Formatted loyalty price without unit that is dependent on shouldShowAip flag, e.g. 123,456 (for miles or points), $123,456 (for cash rewards) */
  const formattedLoyaltyPrice: string | undefined = isNumber(loyaltyPrice) && loyaltyCurrency
    ? formatNumberToLocaleString({ num: loyaltyPrice, unitName: loyaltyCurrency.display_name, shouldIncludeUnitName: false })
    : undefined;

  /** Section name to display */
  const sectionName: string = verboseSectionName || section;

  /** Formatted total number of available tickets, e.g. '123,456' */
  const formattedTicketsTotal: string = formatNumberToLocaleString({ num: ticketsTotal, unitName: undefined, shouldIncludeUnitName: false });

  /** Indicates if the listing has CP1 broker Id 29014 */
  const hasCp1BrokerId: boolean = brokerId === C1_BROKER_ID;

  return {
    ...listing,
    shouldShowAip,
    nonAipCashPrice,
    aipCashPrice,
    cashPrice,
    formattedCashPrice,
    nonAipLoyaltyPrice,
    aipLoyaltyPrice,
    loyaltyPrice,
    formattedLoyaltyPrice,
    loyaltyUnitName: loyaltyCurrency?.display_name,
    sectionName,
    row,
    sellerNotes,
    ticketsTotal,
    formattedTicketsTotal,
    ticketQuantities,
    hasCp1BrokerId,
  };
};

export const buildListingHref = (params: {
  /** Current URL's query string */
  search: string;
  /** Listing Id */
  listingId: string;
  /** Number of tickets */
  ticketQuantity: number;
  /** Indicates if the listing has CP1 broker Id 29014 */
  hasCp1BrokerId: boolean;
}): string => {
  const { search, listingId, ticketQuantity, hasCp1BrokerId } = params;

  return addQueryParam(search, {
    [LISTING_ID_PARAM]: listingId,
    [QUANTITY_PARAM]: ticketQuantity.toString(),
    [EXCLUSIVE_LISTINGS_PARAM]: hasCp1BrokerId.toString(),
  });
};

export const processDeliveryOption = (deliveryId: number): number => {
  switch (deliveryId) {
    case (19): return 20;
    case (31): return 32;
    default: return deliveryId;
  }
};

/** Filters listings by section HTML Id */
export const filterListingsBySectionHtmlId = (params: {
  listings: readonly Listing[];
  /** HTML Id of the selected section, e.g. id-123 */
  sectionHtmlId: string;
}): readonly Listing[] => {
  const { listings, sectionHtmlId } = params;
  return listings.filter((listing: Listing) => listingUtils.getListingSectionHtmlId(listing) === sectionHtmlId);
};

/** Gets an object with HTML Ids of sections where key is section HTML Id and value is always true */
export const getListingSectionHtmlIdMap = (params: {
  listings: readonly Listing[];
}): Record<string, true> => {
  const { listings } = params;

  return listings.reduce((acc: Record<string, true>, listing: Listing) => {
    acc[listingUtils.getListingSectionHtmlId(listing)] = true;
    return acc;
  }, {});
};

/**
 * Gets listing min prices per section as an object where key is section HTML Id and value is min price for the section.
 * This is used to display tooltips with min prices when user hovers over SVG map.
 */
export const getListingMinPricesBySection = (params: {
  listings: readonly Listing[];
}): ListingMinPricesBySection => {
  const { listings } = params;

  return listings.reduce((acc: ListingMinPricesBySection, listing: Listing) => {
    const { price_per: price } = listing;

    const sectionHtmlId: string = listingUtils.getListingSectionHtmlId(listing);

    if (!isNumber(acc[sectionHtmlId]) || acc[sectionHtmlId] > price) {
      acc[sectionHtmlId] = price;
    }

    return acc;
  }, {});
};

/**
 * Calculates the total cash and loyalty prices for a given quantity and formats the values for display
 * @returns {TotalPrices} Object with formatted total cash and loyalty prices for the given quantity
 */
export const getTotalPricesForQuantity = (params: {
  /** Cash price per unit as number that is dependent on shouldShowAip flag, e.g. 123456 */
  cashPrice: number | undefined;
  /** Loyalty price per unit as number that is dependent on shouldShowAip flag, e.g. 123456 */
  loyaltyPrice: number | undefined;
  /** Lower cased loyalty unit name, e.g. 'miles', 'points', etc. */
  loyaltyUnitName: string | undefined;
  /** Selected quantity of tickets */
  quantity: number;
}): TotalPrices => {
  const { cashPrice, loyaltyPrice, loyaltyUnitName, quantity } = params;

  /** Formatted total cash price (with cents) for selected quantity in Quantity dropdown, e.g. $123,456.00 */
  const formattedTotalCashPrice: string | undefined = isNumber(cashPrice)
    ? formatNumberToLocaleString({ num: cashPrice * quantity, unitName: 'dollarsWithCents', shouldIncludeUnitName: false })
    : undefined;

  /** Formatted total loyalty price for selected quantity in Quantity dropdown, e.g. 123,456 (for miles or points), $123,456 (for cash rewards) */
  const formattedTotalLoyaltyPrice: string | undefined = isNumber(loyaltyPrice)
    ? formatNumberToLocaleString({ num: loyaltyPrice * quantity, unitName: loyaltyUnitName, shouldIncludeUnitName: false })
    : undefined;

  return {
    formattedTotalCashPrice,
    formattedTotalLoyaltyPrice,
  };
};

export const getFaceValue = (params: {
  listing: Listing;
}): string | undefined => {
  const { listing } = params;

  if (!listing.show_face_value || listing.broker_id === C1_BROKER_ID) {
    return undefined;
  }

  if (!listing.face_value || listing.face_value <= 0) {
    return i18n.t('priceBreakdownInfo.faceValueAbsent');
  }

  const formattedFaceValue: string = formatNumberToLocaleString({ num: listing.face_value, unitName: 'dollarsWithCents', shouldIncludeUnitName: false });
  return i18n.t('priceBreakdownInfo.faceValuePresent', { faceValue: formattedFaceValue });
};

export const getListingDetailMetadata = (params: {
  /** Listing detail object */
  listingDetail: ListingDetails;
  /** Flag to enforce AIP pricing */
  shouldShowAipOverride: boolean;
  /** Loyalty currency */
  loyaltyCurrency: LoyaltyCurrency | undefined;
}): ListingDetailMetadata => {
  const { listingDetail, shouldShowAipOverride, loyaltyCurrency } = params;
  const { event, listing, ...otherListingDetail } = listingDetail;

  const eventMetadata: EventMetadata = getEventMetadata({ event });

  const listingMetadata: ListingMetadata = getListingMetadata({ listing, shouldShowAipOverride: shouldShowAipOverride || !!listingDetail.preselect_delivery_method, loyaltyCurrency });

  const seats: string | undefined = listingUtils.getSeats({ listingDetail });

  const deliveryMethod: string = i18n.t(getTicketListingTranslationKey(listingDetail, 'delivery_method'));

  const isShippingAddressRequired: boolean = i18n.t(getTicketListingTranslationKey(listingDetail, 'requiresShippingInfo')) === 'true';

  const faceValue: string | undefined = listingUtils.getFaceValue({ listing });

  return {
    ...otherListingDetail,
    eventMetadata,
    listingMetadata,
    seats,
    deliveryMethod,
    isShippingAddressRequired,
    faceValue,
  };
};
