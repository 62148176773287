import { trackErrorEvent, type EventKeys } from '../modules/analytics';
import { getAuthData } from '../modules/auth/AuthApi';
import type { Account, AccountCardDetail } from '../modules/auth/types';
import { ApiError } from '../modules/error/types';
import type { ListingDetails, ListingDetailsQueryParams, UserOrdersResponse } from '../modules/partnership';
import { getListingDetails, getMyOrders } from '../modules/partnership/api';
import { getAccountCardDetails } from './accountUtils';
import { getPreviouslyPurchasedTicketTotal } from './eventUtils';
import { round } from './util';

export type CheckoutEligibility = {
  /** Re-fetched user account */
  refetchedAccount: Account | undefined;
  /** Error state. True if any of the API calls has failed. */
  isCheckoutEligibilityApiError: boolean;
  /**
   * Indicates whether user does not have enough reward units to pay for the tickets.
   * If user applied reward units then True if user no longer has those reward units available.
   * In addition, if the event only allows payment with rewards then True if user does not have enough reward units to pay for the tickets.
   */
  areInsufficientRewardUnits: boolean;
  /** Total number of previously purchased tickets for the same event */
  previouslyPurchasedTicketTotal: number;
  /**
   * Indicates whether the user would cross the limit of the maximum tickets per account per event should they proceed with the purchase.
   * E.g. if the limit is 4 and user has previously purchased 2 tickets for the same event, then:
   * - If they try to buy 1 or 2 more tickets for the same event then the flag will be false (2 + 1 <= 4, 2 + 2 <= 4).
   * - If they try to buy 3 or more tickets for the same event then the flag will be true (2 + 3 > 4).
   */
  isPurchaseLimitCrossed: boolean;
};

/** Function that checks whether the user has enough reward units and whether they are trying to buy too many tickets */
export const checkCheckoutEligibility = async (params: {
  /** Detailed information about event listing detail */
  listingDetail: ListingDetails;
  /** Query parameters to fetch listing detail with quantity, exclusive_listings and delivery_id properties */
  listingDetailQueryParams: ListingDetailsQueryParams;
  /** Currently selected quantity or undefined if no quantity is selected */
  selectedQuantity: number;
  /**
     * Number of applied reward units:
     * - Always 0 when used at pre-checkout.
     * - 0 when checkout loads initially, i.e. user has not yet selected their payment options.
     * - 0 if user does not apply any reward units.
     * - X if user applies X reward units.
     */
  appliedRewardUnits: number;
  /** Indicates if the event only allows payment with rewards */
  isPayWithRewardsOnly: boolean | undefined;
  /** Maximum number of tickets that can be purchased for the event. Undefined if there is no limit. */
  eventMaxPurchaseLimit: number | undefined;
  /** Function to set account manually. Called during checkout eligibility checks. */
  setAccountExternally: (newAccount: Account) => void;
  /** 0-based index of the selected account card */
  selectedAccountCardIndex: number;
  /** Function to select account card by 0-based index */
  selectAccountCardByIndex: (newSelectedAccountCardIndex: number) => void;
  trackEvent: (event: EventKeys, extraData?: Record<string, unknown>) => void;
}): Promise<CheckoutEligibility> => {
  const {
    listingDetail,
    listingDetailQueryParams,
    selectedQuantity,
    appliedRewardUnits,
    isPayWithRewardsOnly,
    eventMaxPurchaseLimit,
    setAccountExternally,
    selectedAccountCardIndex,
    selectAccountCardByIndex,
    trackEvent,
  } = params;

  let newRefetchedAccount: Account | undefined;
  let newIsCheckoutEligibilityApiError: boolean = false;
  let newAreInsufficientRewardUnits: boolean = false;
  let newPreviouslyPurchasedTicketTotal: number = 0;
  let newIsPurchaseLimitCrossed: boolean = false;

  const {
    event: {
      id: eventId,
    },
    listing: {
      id: listingId,
    },
    pricing: {
      quantity: lastFetchedQuantity,
      total: lastFetchedTotal,
    },
  } = listingDetail;

  try {
    let hasError: boolean = false;

    // Re-fetch user account and update AuthContext with it.
    // This will give us the latest values for the user account.
    // Required before entering checkout and before placing an order to ensure we perform checks against the up-to-date values.
    const prevSelectedAccountCardIndex: number = selectedAccountCardIndex;
    newRefetchedAccount = await getAuthData();
    setAccountExternally(newRefetchedAccount);
    selectAccountCardByIndex(prevSelectedAccountCardIndex);

    // Loyalty currencies are not used for the following logic so pass undefined value
    const accountCardDetail: AccountCardDetail | undefined = getAccountCardDetails({ account: newRefetchedAccount, loyaltyCurrencies: undefined })[prevSelectedAccountCardIndex];
    if (!accountCardDetail) {
      newAreInsufficientRewardUnits = true;
      hasError = true;
    } else {
      const { rewardUnitsTotal, rewardUnitsPerDollar, isIntegerRewardUnit } = accountCardDetail;

      // If user applied reward units then check that user still has those reward units available
      if (appliedRewardUnits > rewardUnitsTotal) {
        newAreInsufficientRewardUnits = true;
        hasError = true;
      }

      // The following applies only to events that only allow payments with rewards.
      // Check whether user has enough reward units to pay for the tickets.
      if (!hasError && isPayWithRewardsOnly) {
        /** Total cash price for the selected ticket quantity */
        let totalPriceInCash: number = lastFetchedTotal;

        // If currently selected ticket quantity is not the same as the last fetched ticket quantity,
        // Then re-fetch listing detail for the selected ticket quantity.
        // This will give us the total price of the tickets that the user wants to buy.
        if (selectedQuantity !== lastFetchedQuantity) {
          const newListingDetail: ListingDetails = await getListingDetails(
            eventId.toString(),
            listingId,
            // Use the same API parameters but with the selected ticket quantity
            { ...listingDetailQueryParams, quantity: selectedQuantity },
          );

          totalPriceInCash = newListingDetail.pricing.total;
        }

        /** Total price in reward units for the selected ticket quantity */
        const totalPriceInRewardUnits: number = round({ amount: totalPriceInCash * rewardUnitsPerDollar, rounding: 'up', isInteger: isIntegerRewardUnit });

        // Peform the check
        newAreInsufficientRewardUnits = totalPriceInRewardUnits > rewardUnitsTotal;
        hasError = newAreInsufficientRewardUnits;
      }
    }

    // The following applies only to events that have a maximum purchase limit.
    // Check whether the user would cross the limit of the maximum tickets per account per event should they proceed with the purchase.
    if (!hasError && eventMaxPurchaseLimit) {
      /** Details about user's previous orders */
      const userOrdersResponse: UserOrdersResponse = await getMyOrders();

      /** Total number of previously purchased tickets for the same event */
      newPreviouslyPurchasedTicketTotal = getPreviouslyPurchasedTicketTotal({ previousOrders: userOrdersResponse.orders, eventId });

      // Peform the check
      newIsPurchaseLimitCrossed = eventMaxPurchaseLimit < (selectedQuantity + newPreviouslyPurchasedTicketTotal);
      hasError = newIsPurchaseLimitCrossed;
    }
  } catch (error) {
    newIsCheckoutEligibilityApiError = true;

    if (error && ApiError.isApiError(error)) {
      trackErrorEvent(trackEvent, error.code, error.message);
    }
  }

  return {
    refetchedAccount: newRefetchedAccount,
    isCheckoutEligibilityApiError: newIsCheckoutEligibilityApiError,
    areInsufficientRewardUnits: newAreInsufficientRewardUnits,
    previouslyPurchasedTicketTotal: newPreviouslyPurchasedTicketTotal,
    isPurchaseLimitCrossed: newIsPurchaseLimitCrossed,
  };
};
